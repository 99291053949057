/* eslint-disable react-hooks/exhaustive-deps */
import "./i18n";

import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PATH } from "constants/path";
import { useIP } from "hooks/useIP";
import LoadingPage from "pages/Common/LoadingPage";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useAppToken } from "stores/auth-state";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: false,
    },
  },
});

const timeReLogin = 1000 * 60 * 10;
function App() {
  const { t } = useTranslation();
  const token = useAppToken((state) => state.token);
  const setToken = useAppToken((state) => state.setToken);
  const appRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useIP();

  useEffect(() => {
    if (!token) {
      clearInterval(appRef.current);
      return;
    }
    clearInterval(appRef.current);
    appRef.current = setInterval(() => {
      handleLogoutEffect();
    }, timeReLogin);
    window.addEventListener("click", () => {
      clearInterval(appRef.current);
      appRef.current = setInterval(() => {
        handleLogoutEffect();
      }, timeReLogin);
    });
    return () => {
      clearInterval(appRef.current);
    };
  }, [token]);
  const handleLogoutEffect = () => {
    clearInterval(appRef.current);
    window.confirm(t("logout"));
    setToken("");
    queryClient.clear();
    localStorage.clear();
    window.location.href = PATH.ReLogin;
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        {token ? <Outlet /> : <LoadingPage />}
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

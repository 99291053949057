import { css } from "@emotion/react";

import { theme } from "./theme";
import { media } from "./breakpoints";

export const reset = css`
  /* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */
  * {
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  *:not(.non-reset *) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  body {
    margin: 0;
    padding: 0px;
    background: ${theme.colors.background};
    color: ${theme.colors.textColor};
  }
  a {
    text-decoration: none;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &:root {
    --left-width: 300px;
    --header-height: 35px;
    --full-height: calc(100vh - var(--header-height));
    --height-center: calc(var(--full-height) / 2);
    --height-arena: 5vh;
    --video-height: calc(var(--height-center) - var(--height-arena));
    --height-status-bar: 4vh;
    --height-bet-on: calc(var(--height-center) / 2 - var(--height-status-bar));
    --height-buttons: 5vh;
    --height-history: calc(
      var(--height-center) / 2 - var(--height-buttons) - 4vh
    );
  }
  ${media.md} {
    &:root {
      --full-height: calc(100vh);
      --height-arena: 4.5vh;
      --video-height: calc(var(--full-height) / 10 * 4.5 - var(--height-arena));
      --height-status-bar: 4.5vh;
      --height-bet-on: calc(
        var(--full-height) / 10 * 2.8 - var(--height-status-bar)
      );
      --height-buttons: 5.5vh;
      --height-history: calc(
        var(--full-height) / 10 * 2.2 - var(--height-buttons) - 0.5vh
      );
    }
  }
`;

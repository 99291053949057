export interface LanguageProps {
  name: string;
  code: string;
}
export const Languages: LanguageProps[] = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "中文",
    code: "cn",
  },
  {
    name: "Tiếng Việt",
    code: "vi",
  },
  {
    name: "日本語",
    code: "jp",
  },
  {
    name: "ไทย",
    code: "th",
  },
  {
    name: "ESPAÑOL",
    code: "es",
  },
  {
    name: "Malay",
    code: "ms",
  },
  {
    name: "Indonesia",
    code: "id",
  },
  {
    name: "Khmer",
    code: "kh",
  },
];

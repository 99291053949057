export const PATH = {
  play: "/play",
  Root: "/",
  HomePage: "/",
  MHomePage: "/m",
  MatchHistory: "/match-history",
  Schedule: "/schedule",
  Guide: "/guide",
  TermsConditions: "/terms-conditions",
  MMatchHistory: "/m/match-history",
  MGuidePage: "/m/guide",
  MTermsConditions: "/m/terms-conditions",
  MSchedule: "/m/schedule",
  error: "/error",
  LoginPage: "/login",
  ReLogin: "/re-login",
  MReLogin: "/m/re-login",
  message: "/message",
  MMessage: "/m/message",
};

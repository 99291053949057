import { css, keyframes } from "@emotion/react";
import { theme } from "styles/theme";
const spin = keyframes`
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
`;
export const DefaultLayoutStyle = {
  self: css`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .spin {
      animation: ${spin} 1s linear infinite;
    }

    a {
      color: white;
    }
  `,
  index: css`
    flex: 1;
    background-color: ${theme.colors.background};
  `,
};
